import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import AnouncementBar from '../components/home/announcement-bar';
import CustomerReviews from '../components/services/reviews';
import HeroBanner from '../components/services/hero-banner';
import Breadcrumb from '../components/services/breadcrumb';
import Intro from '../components/services/intro';
import SearchForm from '../components/services/search-form';
import MoreInfo from '../components/services/more-info';
import '../assets/styles/templates/sevices-list.scss';

const Service = ({ data }) => {
  const { seopages } = data;
  const { seo, breadcrumbs, exploreInfo, items, jumbotron, moreInfo, reviews, searchText, searchPlaceholder } = seopages;

  return (
    <Layout wrapperClass="standard-page service-list-wrapper" showSiteIndex={true} hideHeaderFooter={true}>
      <SEO
        title={seo.title}
        description={seo.title}
        canonical={seo.canonical}
        stucturedData=""
        ogTitle={seo.og.title}
        ogDescription={seo.og.description}
        ogImage={seo.og.image}
        ogType={seo.og.type}
        ogUrl={seo.og.url}
      />
      <AnouncementBar />
      <HeroBanner subHeading={jumbotron.header} title={jumbotron.body} btnText={jumbotron.button} />
      <Breadcrumb breadcrumbs={breadcrumbs} />
      {searchText && (
        <SearchForm searchTitle={searchText} placeholderCopy={searchPlaceholder} />
      )}
      <div className="container">
        {exploreInfo && (
          <Intro title={exploreInfo.header} text={exploreInfo.body} classWrapper="intro" />
        )}
        <div className={`row ${seopages.type}`}>
          {items.map((node) => (
            <a href={node.url} className="service-tile col-3 text-center" key={node.name}>
              <div className="card">
                <div className="card-body">
                  {node.photoLink.local && (
                    <div className="service-tile-image">
                      <Img fluid={node.photoLink.local.childImageSharp.fluid} />
                    </div>
                  )}
                  <h3>{node.name}</h3>
                </div>
              </div>
            </a>
          ))}
        </div>
        <MoreInfo moreCTA={moreInfo.title} moreText={moreInfo.body} moreTitle={moreInfo.header} />
      </div>
      <CustomerReviews reviews={reviews} />
    </Layout>
  );
};

export const query = graphql`
  query ServiceQuery($url: String!) {
    seopages(url: { eq: $url }) {
      searchText
      searchPlaceholder
      breadcrumbs {
        text
        url
      }
      exploreInfo {
        body
        header
      }
      items {
        name
        photoLink {
          local {
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        alt
        url
      }
      jumbotron {
        body
        button
        header
      }
      moreInfo {
        body
        header
        title
      }
      name
      type
      reviews {
        customerName
        pbName
        rating
        reviewDate
        reviewText
      }
      seo {
        canonical
        description
        heading
        id
        og {
          description
          image
          title
          type
          url
        }
        title
      }
      title
      url
    }
  }
`;

Service.propTypes = {
  data: PropTypes.shape().isRequired,
  pageContext: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Service;
